import { EntrySettingValue, SettingCodes } from '../model/questionnaire'
import { getSetting } from './question'

export const updateMultiSelectIntervalBoundariesSettings = (
  entrySettingValues: EntrySettingValue[],
  responseOptionsLength: number
): EntrySettingValue[] => {
  const minIntervalVal = Math.min(
    Number(getSetting(entrySettingValues, SettingCodes.ChoiceIntervalMin)) ?? 0,
    responseOptionsLength
  )
  const maxIntervalVal = Math.min(
    Number(getSetting(entrySettingValues, SettingCodes.ChoiceIntervalMax)) ?? 0,
    responseOptionsLength
  )

  const updatedSettingValues = entrySettingValues?.map((setting) => {
    if (setting.code === SettingCodes.ChoiceIntervalMin) {
      return {
        ...setting,
        value: minIntervalVal.toString()
      }
    }
    if (setting.code === SettingCodes.ChoiceIntervalMax) {
      return {
        ...setting,
        value: maxIntervalVal.toString()
      }
    }
    return setting
  }) as EntrySettingValue[]

  return updatedSettingValues
}
